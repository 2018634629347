import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { SpinnerProps } from ".";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const SpinnerStyle = styled.div<SpinnerProps>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  border: ${(props) => Number(props.size) / 8}px solid ${(props) => props.color};
  border-color: ${(props) => props.color} transparent transparent transparent;
  animation: ${spin} 1.2s linear infinite;
`;
