import styled from "@emotion/styled";
import { ActivityComponentType } from "@stackflow/react";

import { AppScreen } from "../../stackflow/components";
import {
  Button,
  Container,
  Title,
  Text,
  Image,
  ButtonWrapper,
  ShareIconWrapper,
  CloseButton,
} from "./index.css";

import ShareIcon from "../../assets/share.svg";
import PopOverRight from "../../assets/popover_right.png";
import { Spacing } from "@components/common/Spacing";
import { useFlow } from "../../stackflow";
import useActiveActivities from "@/stackflow/hooks/useActiveActivities";
import { copyToClipboard } from "@utils/link";
import { CLIENT_URL } from "@/config/path";
import { useEnterTrackEvent } from "@hooks/useEnterTrackEvent";

const SendDone: ActivityComponentType = () => {
  const { push, pop } = useFlow();
  const { diffActivitiesFromCurrent } = useActiveActivities();

  useEnterTrackEvent({
    event: "enter_send_done",
  });
  const handleMoveToMyPage = () => {
    // 기존 마이페이지에서 수정하고 파트너 번호 입력했을 경우 플로우
    const diffToMyPage = diffActivitiesFromCurrent("MyPage");
    if (diffToMyPage !== -1) {
      pop(diffToMyPage);
      return;
    }

    // 신규 가입했을 경우 플로우
    const diffToHomePage = diffActivitiesFromCurrent("HomePage");
    if (diffToHomePage !== -1) {
      pop(diffToHomePage);
      push(
        "MyPage",
        {},
        {
          animate: false,
        }
      );
      return;
    }

    // 예상 외의 플로우인 경우 일단 push
    push("MyPage", {});
  };

  const handleShareLink = async () => {
    handleMoveToMyPage();
    push("ShareTemplatePage", {});
  };

  return (
    <AppScreen
      noAppBar
      accessoryBar={
        <ButtonWrapper>
          <Button onClick={handleShareLink}>
            <ShareIconWrapper src={ShareIcon} />
            링크 공유하기
          </Button>
          <Spacing height={4} />
          <CloseButton onClick={handleMoveToMyPage}>닫기</CloseButton>
        </ButtonWrapper>
      }
    >
      <Container>
        <Image src={PopOverRight} />
        <Spacing height={24} />
        <Title>상대방 마음을 기다리고 있어요...</Title>
        <Spacing height={50} />
        <Text
          style={{
            color: "#ffffff",
            fontWeight: "900",
            fontSize: "18px",
          }}
        >
          TIP
        </Text>
        <Spacing height={16} />
        <Text
          style={{
            maxWidth: "300px",
            wordBreak: "keep-all",
            lineHeight: "1.8rem",
            fontWeight: "400",
          }}
        >
          짝사랑과 함께하고 있는 단톡방과 주변에
          <br />
          은근슬쩍 서비스 링크를 공유해
          <br />
          상대가 나를 지목할 가능성을 높여보세요.
        </Text>
      </Container>
    </AppScreen>
  );
};

export default SendDone;
