import React from "react";

import { SpinnerStyle } from "./index.css";

export interface SpinnerProps {
  size?: number; // Spinner의 크기를 설정할 수 있는 옵션
  color?: string; // Spinner의 색상을 설정할 수 있는 옵션
}

const Spinner: React.FC<SpinnerProps> = ({ size = 40, color = "#000" }) => {
  return <SpinnerStyle size={size} color={color}></SpinnerStyle>;
};

export default Spinner;
