import { AppScreen } from "@/stackflow/components";
import { ActivityComponentType } from "@stackflow/react";
import React, { useEffect, useMemo, useState } from "react";
import PartnerInfoSection from "./components/partnerInfoSection";
import ReceiveHintSection from "./components/ReceiveHintSection";

import ShareIcon from "../../assets/share.svg";
import SettingIcon from "../../assets/setting.png";

import {
  Button,
  ButtonWrapper,
  Container,
  LeftBox,
  ProfileImage,
  ProfileImageWrapper,
  RenderRightWrapper,
  SettingIconImg,
  SettingIconWrapper,
  SubTitle,
  Title,
  TitleSection,
  TopWrapper,
} from "./index.css";
import { diffRemainDateToGoback } from "@utils/date";
import useGetMe, { MeResponse } from "@/apis/hooks/useGetMe";
import AsyncBoundary from "@components/common/AsyncBoundary/AsyncBoundary";
import { copyToClipboard } from "@utils/link";
import { CLIENT_URL } from "@/config/path";
import { useFlow } from "@/stackflow";
import { openDefaultSnackbar } from "@utils/toast";

import ToolSection from "./components/ToolSection";

import { useEnterTrackEvent } from "@hooks/useEnterTrackEvent";
import { trackAmplitudeEvent } from "@hooks/useInitAmplitude";

const MyPage: ActivityComponentType = () => {
  const { data: meData, status, isLoading } = useGetMe();
  const { replace } = useFlow();

  useEffect(() => {
    console.log("data", meData, status);
    if (!isLoading && !meData) {
      replace(
        "HomePage",
        {},
        {
          animate: false,
        }
      );
      openDefaultSnackbar("로그인에 실패했습니다. 다시 시도해주세요.", 1000);
    }
  }, [isLoading, meData, replace, status]);

  return (
    <AsyncBoundary>
      {meData ? <MyPageInner meData={meData} /> : null}
    </AsyncBoundary>
  );
};

const MyPageInner = ({ meData }: { meData: MeResponse }) => {
  const { push } = useFlow();

  const diffRemainDate = useMemo(() => {
    return diffRemainDateToGoback();
  }, []);

  const handleShareLink = async () => {
    trackAmplitudeEvent("click_share_button");
    push("ShareTemplatePage", {});
  };

  const handleMoveToSomeTool = () => {
    trackAmplitudeEvent("click_check_some_page_button");
    push("CheckSomePage", {});
  };

  useEnterTrackEvent({
    event: "enter_mypage",
  });

  return (
    <AppScreen
      appBar={{
        iconColor: "#ffffff",
        renderRight: () => (
          <RenderRightWrapper onClick={handleShareLink}>
            <img src={ShareIcon} alt="share" width={24} height={24} />
          </RenderRightWrapper>
        ),
      }}
    >
      <Container>
        <TitleSection>
          <TopWrapper>
            <Title>
              <span>고백데이까지</span>
              <span>
                <span className="bold-date">{diffRemainDate}일</span> 남았어요
              </span>
            </Title>
            {meData.user?.kakao_profile_img.Valid && (
              <ProfileImageWrapper>
                <SettingIconWrapper
                  onClick={() => {
                    push("SettingPage", {});
                  }}
                >
                  <SettingIconImg src={SettingIcon} alt="setting" />
                </SettingIconWrapper>

                <ProfileImage
                  src={meData.user.kakao_profile_img.String}
                  alt="popOverRight"
                />
              </ProfileImageWrapper>
            )}
          </TopWrapper>
          <SubTitle>짝사랑에게 은근슬쩍 링크를 공유해보세요.</SubTitle>
        </TitleSection>
        <PartnerInfoSection
          meData={meData}
          sendHint={meData.sendHint.sent}
          canSendHint={meData.sendHint.available}
        />
        <ReceiveHintSection receiveHint={meData.receiveHint} />

        <ToolSection />
      </Container>
    </AppScreen>
  );
};

export default MyPage;
