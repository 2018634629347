import { CLIENT_URL } from "@/config/path";
import { useFlow } from "@/stackflow";
import { trackAmplitudeEvent } from "@hooks/useInitAmplitude";

import { Button } from "./SocialLoginButton.css";

const NextHomeButton = () => {
  const Rest_api_key = "fda70bb7fe969022fa3ddc45f12145d1"; //REST API KEY
  const redirect_uri = `${CLIENT_URL}/auth`; //Redirect URI
  // oauth 요청 URL
  const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${Rest_api_key}&redirect_uri=${redirect_uri}&response_type=code`;

  const { push } = useFlow();

  const handleNext = () => {
    trackAmplitudeEvent("click_next_home");
    push("SetMyPhoneInfoPage", {});
  };

  return <Button onClick={handleNext}>처음 왔어요</Button>;
};

export default NextHomeButton;
