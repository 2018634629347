import styled from "@emotion/styled";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background-color: #6d5aed;

  border-radius: 1rem;
  padding: 1.25rem;

  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  cursor: pointer;

  &:active {
    background-color: #7d6cf0;
  }
`;

export const LeftBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const PrefixIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.75rem;
`;

export const ContentsWrapper = styled.div`
  display: flex;

  align-items: center;
  justify-content: flex-start;

  color: #ffffff;

  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;

  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const SubContentsWrapper = styled.span`
  color: #ffffff;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;

  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const RightBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;

  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
`;
