import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
  padding: 0 2rem;
  box-sizing: border-box;
`;
export const Title = styled.div`
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.025rem;
  letter-spacing: -3%;
  word-break: keep-all;
`;
export const Text = styled.div`
  text-align: center;
  color: #d4d0f4;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -3%;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0.75rem 1.5rem;
  box-sizing: border-box;
`;

export const Button = styled.button`
  width: 100%;
  flex: 1;
  padding: 1rem 0;
  background-color: #0e0e11;
  color: #fff;
  border: none;
  border-radius: 3.5rem;
  font-family: "SUIT-Regular";
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

export const ShareIconWrapper = styled.img`
  width: 24px;
`;

export const Image = styled.img`
  width: auto;
  height: 2.625rem;
`;

export const CloseButton = styled.div`
  cursor: pointer;
  padding: 1rem;

  color: #ffffff;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
