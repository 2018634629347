import { MeResponse } from "@/apis/hooks/useGetMe";
import HintBoxButton from "../HintBoxButton";
import {
  Container,
  ContentsWrapper,
  SubTitle,
  Title,
  TitleWrapper,
} from "./index.css";

type Props = {
  receiveHint: MeResponse["receiveHint"];
};

// 받은 힌트 목록 섹션
function ReceiveHintSection({ receiveHint }: Props) {
  if (!receiveHint?.hints || receiveHint.hints.length === 0) return null;

  return (
    <Container>
      <TitleWrapper>
        <Title>상대가 보낸 힌트</Title>
        <SubTitle>나에게 마음을 전한 사람은 누구일까요?</SubTitle>
      </TitleWrapper>
      <ContentsWrapper>
        {receiveHint.hints.map((item, index) => (
          <HintBoxButton key={index} item={item} />
        ))}
      </ContentsWrapper>
    </Container>
  );
}

export default ReceiveHintSection;
