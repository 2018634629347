import { SERVER_URL } from "@/config/path";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { fetchInstance } from "../instance";

export const deleteUserPath = `${SERVER_URL}/user`;

export const deleteUser = async () => {
  const jwt = window.sessionStorage.getItem("token");
  const { data } = await fetchInstance({ jwt: jwt ?? "" }).delete<
    undefined,
    AxiosResponse<undefined>
  >(deleteUserPath);

  return data;
};

type Props = UseMutationOptions;

export const useDeleteUser = ({ onError, onSuccess }: Props) => {
  return useMutation({
    mutationFn: async () => {
      const result = await deleteUser();
      return result;
    },
    onError,
    onSuccess,
  });
};

export default useDeleteUser;
