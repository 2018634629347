import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  flex: 1;
  padding: 0 2rem;
  box-sizing: border-box;
`;
export const Title = styled.div`
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: -3%;
`;
export const Text = styled.div`
  text-align: center;
  color: #d4d0f4;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -3%;
`;

export const Image = styled.img`
  width: auto;
  height: 1.75rem;
`;

export const HitListGrid = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
`;

export const HintItem = styled.div<{ isSelected: boolean }>`
  padding: 50px 0 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  background: #6d5aed;
  gap: 0.75rem;

  position: relative;

  border: 2px solid ${(props) => (props.isSelected ? "#F76EC9" : "transparent")};
`;

export const HintItemText = styled.div`
  color: #ffffff;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6875rem;
  font-family: "SUIT-Bold";
  font-weight: 700;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0.75rem 1.5rem;
  box-sizing: border-box;
`;

export const Button = styled.button`
  width: 100%;
  flex: 1;
  padding: 1rem 0;
  background-color: #0e0e11;
  color: #fff;
  border: none;
  border-radius: 3.5rem;
  font-family: "SUIT-Regular";
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

export const SelectedCircleIconImg = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
`;
