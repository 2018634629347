import { CLIENT_URL } from "@/config/path";
import { trackAmplitudeEvent } from "@hooks/useInitAmplitude";

import { Button, LoginButton } from "./SocialLoginButton.css";

const SocialLoginButton = () => {
  const Rest_api_key = "fda70bb7fe969022fa3ddc45f12145d1"; //REST API KEY
  const redirect_uri = `${CLIENT_URL}/auth`; //Redirect URI
  // oauth 요청 URL
  const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${Rest_api_key}&redirect_uri=${redirect_uri}&response_type=code`;

  const handleLogin = () => {
    trackAmplitudeEvent("click_kakao_login");
    const result = confirm(
      "상대방이 나를 좋아하는지 확인하기 위해 우선 카카오톡 로그인이 필요해요.\n\n(‼️9월 17일에 매칭되지 않는다면 누구에게도 내 정보는 절대 공개되지 않아요.)"
    );
    if (!result) return;
    trackAmplitudeEvent("click_kakao_login_confirm");
    window.location.href = kakaoURL;
  };

  return <LoginButton onClick={handleLogin}>로그인 할래요</LoginButton>;
};

export default SocialLoginButton;
