import { SERVER_URL } from "@/config/path";
import { NullableInt64, NullableString } from "@/types/common";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { fetchInstance } from "../instance";

export type MeResponse = {
  id: number;
  kakao_id: string;
  kakao_email: string;
  kakao_profile_img: NullableString;
  my_phone_number: NullableInt64;
  partner_name: NullableString;
  partner_number: NullableInt64;
  token: string;
};

export const getAuthKakaoPath = (code?: string | null) =>
  `${SERVER_URL}/auth/kakao?code=${code}`;

export const getAuthKakao = async (code?: string | null) => {
  const { data } = await fetchInstance({}).get<
    undefined,
    AxiosResponse<MeResponse>
  >(getAuthKakaoPath(code));

  return data;
};

export const useGetAuthKakao = ({ code }: { code?: string | null }) => {
  const { data, refetch, isLoading } = useQuery({
    queryKey: [getAuthKakaoPath(code)],
    queryFn: () => getAuthKakao(code),
    enabled: !!code,
  });

  const response = data;

  return { data: response, refetch, isLoading, status };
};

export default useGetAuthKakao;
