import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  flex: 1;
  padding: 1rem;
  box-sizing: border-box;
  color: #ffffff;
  word-break: keep-all;

  p {
    line-height: 1.5rem;
  }
`;

export const GuideImage = styled.img`
  width: 80%;
  max-height: 20.9375rem;
  height: auto;
  object-fit: contain;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  padding: 24px 24px 16px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StemImg = styled.img`
  width: 75px;
  height: auto;
`;

export const Title = styled.div`
  color: #ffffff;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.025rem;
  letter-spacing: -3%;
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0.75rem 1.5rem;
  box-sizing: border-box;
`;
export const Button = styled.button`
  width: 100%;
  flex: 1;
  padding: 1rem 0;
  background-color: #0e0e11;
  color: #fff;
  border: none;
  border-radius: 3.5rem;
  font-family: "SUIT-Regular";
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  cursor: pointer;
`;
