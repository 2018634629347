import { ActivityComponentType } from "@stackflow/react";

import { AppScreen } from "../../stackflow/components";
import {
  NotiText,
  Button,
  Container,
  Title,
  Image,
  ButtonWrapper,
  DontKnowPhoneNumberText,
  ChevronRightIconImg,
  SubTitle,
} from "./index.css";
import ChevronRightIcon from "../../assets/shape_chevron_right.svg";

import PartnerPopOverRightIcon from "../../assets/partner_popover_right.png";
import { Spacing } from "@components/common/Spacing";
import { useFlow } from "../../stackflow";
import Input from "@components/common/Input";
import { useState } from "react";

import { useQueryParams } from "@/stackflow/hooks/useQueryParams";
import usePutUser from "@/apis/hooks/usePutUser";
import { queryClient } from "@/App";
import { getMePath } from "@/apis/hooks/useGetMe";
import { useEnterTrackEvent } from "@hooks/useEnterTrackEvent";
import { CLIENT_URL } from "@/config/path";
import { trackAmplitudeEvent } from "@hooks/useInitAmplitude";

const SetPartnerPhoneInfo: ActivityComponentType = () => {
  const { push } = useFlow();
  const { mode = "add", myNumber = "", logined } = useQueryParams();
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("010");

  useEnterTrackEvent({
    event: "enter_set_partner_phone_info",
    params: {
      mode,
      logined,
    },
  });

  const [phoneNumberError, setPhoneNumberError] = useState({
    isError: false,
    message: "",
  });
  const [nameError, setNameError] = useState({
    isError: false,
    message: "",
  });

  const { mutate: putUser } = usePutUser({
    onError: (error) => {
      console.log(error);
    },
    onSuccess: () => {
      if (mode === "edit") {
        setTimeout(() => {
          queryClient.refetchQueries({
            queryKey: [getMePath],
          });
        }, 500);
      }
      push("SendDone", {});
    },
  });

  const handleSubmitPartnerInfo = async () => {
    // 내번호와 같은 경우
    if (myNumber.length !== 0 && myNumber === phoneNumber) {
      setPhoneNumberError({
        isError: true,
        message: "내 번호와 상대방 번호는 같을 수 없어요.",
      });
      return;
    }

    // 문제 있는 경우
    if (name.length < 1 || phoneNumber.length !== 11) {
      if (name.length < 1) {
        setNameError({
          isError: true,
          message: "이름을 입력해주세요.",
        });
      }

      if (phoneNumber.length !== 11) {
        setPhoneNumberError({
          isError: true,
          message: "휴대폰 번호를 다시 확인해주세요.",
        });
      }
      return;
    }

    trackAmplitudeEvent("click_submit_partner_info", {
      logined,
      mode,
    });
    if (logined) {
      putUser({
        my_phone_number: Number(myNumber),
        partner_name: name,
        partner_number: Number(phoneNumber),
      });
      return;
    }

    if (mode === "edit") {
      putUser({
        partner_name: name,
        partner_number: Number(phoneNumber),
      });
      return;
    }

    const result = confirm(
      "상대가 나를 좋아하는지 확인하기 위해 카카오 로그인이 필요해요"
    );
    trackAmplitudeEvent("show_partner_number_kakao_login", {
      logined,
      mode,
      result,
    });
    if (!result) return;

    const Rest_api_key = "fda70bb7fe969022fa3ddc45f12145d1"; //REST API KEY
    const redirect_uri = `${CLIENT_URL}/auth`; //Redirect URI

    window.sessionStorage.setItem("myNumber", myNumber);
    window.sessionStorage.setItem("partnerName", name);
    window.sessionStorage.setItem("partnerNumber", phoneNumber);
    // oauth 요청 URL
    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${Rest_api_key}&redirect_uri=${redirect_uri}&response_type=code`;

    window.location.href = kakaoURL;
  };

  const openDontKnowPhoneNumber = () => {
    push("NeedPartnerPhoneNumberModal", {});
  };

  const handleOnChangePhoneNumber = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value.length === 11) {
      setPhoneNumberError({
        isError: false,
        message: "",
      });
    }
    if (e.target.value.length > 11) return;
    setPhoneNumber(e.target.value);
  };

  const handleOnChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 1) {
      setNameError({
        isError: false,
        message: "",
      });
    }
    if (e.target.value.length > 6) return;
    setName(e.target.value);
  };

  const accesoryBarButtonText =
    mode === "edit" ? "수정하기" : "상대방 마음 확인하기";

  const handleSkip = () => {
    trackAmplitudeEvent("click_skip_partner_info");
    if (logined) {
      const result = confirm(
        "짝사랑 정보를 입력하지 않으면 매칭 여부를 알 수 없어요.🥲 그래도 건너뛸까요?"
      );
      if (!result) return;
      push("MyPage", {});
      return;
    }
    const result = confirm(
      "상대가 나를 좋아하는지 확인하기 위해 카카오 로그인이 필요해요"
    );
    if (!result) return;

    const Rest_api_key = "fda70bb7fe969022fa3ddc45f12145d1"; //REST API KEY
    const redirect_uri = `${CLIENT_URL}/auth`; //Redirect URI

    window.sessionStorage.setItem("myNumber", myNumber);
    window.sessionStorage.setItem("partnerName", name);
    window.sessionStorage.setItem("partnerNumber", phoneNumber);
    // oauth 요청 URL
    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${Rest_api_key}&redirect_uri=${redirect_uri}&response_type=code`;

    window.location.href = kakaoURL;
  };

  return (
    <AppScreen
      appBar={{
        iconColor: "#ffffff",
        renderRight: () =>
          mode === "edit" ? null : (
            <SubTitle onClick={handleSkip}>건너뛰기</SubTitle>
          ),
      }}
      accessoryBar={
        <ButtonWrapper>
          <Button onClick={handleSubmitPartnerInfo}>
            {accesoryBarButtonText}
          </Button>
        </ButtonWrapper>
      }
    >
      <Container>
        <Spacing height={24} />
        <Image src={PartnerPopOverRightIcon} />
        <Spacing height={16} />
        <Title>
          짝사랑하고 있는 사람이 있나요?
          {/* <br />
          제가 도와드릴게요. */}
        </Title>
        <Spacing height={12} />
        <SubTitle>
          상대방도 나를 짝사랑하고 있는지 확인하고 싶다면,
          <br />
          상대방의 정보를 입력해주세요.
        </SubTitle>
        <Spacing height={30} />
        <Input
          prefixIcon={"🙋"}
          placeholder="상대방 닉네임을 입력해주세요"
          type={"text"}
          max={10}
          onChange={handleOnChangeName}
          value={name}
          isError={nameError.isError}
          errorMessage={nameError.message}
        />
        <Spacing height={20} />
        <Input
          prefixIcon={"📱"}
          placeholder="휴대폰 번호를 입력해주세요"
          type={"number"}
          inputMode="numeric"
          max={11}
          maxLength={11}
          onChange={handleOnChangePhoneNumber}
          value={phoneNumber}
          isError={phoneNumberError.isError}
          errorMessage={phoneNumberError.message}
        />
        <Spacing height={12} />
        <DontKnowPhoneNumberText onClick={openDontKnowPhoneNumber}>
          <span>상대의 휴대폰 번호를 몰라요</span>
          <ChevronRightIconImg src={ChevronRightIcon} />
        </DontKnowPhoneNumberText>
        <Spacing height={30} />
        <NotiText
          style={{
            color: "#F76EC9",
            fontWeight: 700,
          }}
        >
          🌟 내가 좋아하는 상대방과 매칭되지 않는다면
          <br />
          정보는 어디에도 절대 공개되지 않아요 🌟
        </NotiText>
      </Container>
    </AppScreen>
  );
};

export default SetPartnerPhoneInfo;
