import { SERVER_URL } from "@/config/path";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
} from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { fetchInstance } from "../instance";

export type UserPutRequest = {
  my_phone_number?: number;
  partner_name?: string | null;
  partner_number?: number | null;
  hint_message?: string | null;
};

export const putUserPath = `${SERVER_URL}/user`;

export const putUser = async (body: UserPutRequest) => {
  const jwt = window.sessionStorage.getItem("token");
  const { data } = await fetchInstance({ jwt: jwt ?? "" }).put<
    undefined,
    AxiosResponse<undefined>
  >(putUserPath, {
    ...body,
  });

  return data;
};

type Props = UseMutationOptions<undefined, Error, UserPutRequest>;

export const usePutUser = ({ onError, onSuccess }: Props) => {
  return useMutation({
    mutationFn: async (body: UserPutRequest) => {
      const result = await putUser(body);
      return result;
    },
    onError,
    onSuccess,
  });
};

export default usePutUser;
