import React from "react";
import { EndContents, Icon } from "./PartnerInfoBoxButton.css";
import ChevronRightIcon from "@/assets/chevron_right.png";
import BoxButton from "@components/common/BoxButton";
import TrashIcon from "@/assets/trash_icon.png";
import HeartIcon from "@/assets/heart_icon.png";
import { useFlow } from "@/stackflow";
import usePutUser from "@/apis/hooks/usePutUser";
import { queryClient } from "@/App";
import { getMePath } from "@/apis/hooks/useGetMe";

type Props = {
  partnerInfo?: {
    name: string;
    phoneNumber?: string;
  };
};

function PartnerInfoBoxButton({ partnerInfo }: Props) {
  const hasPartnerInfo =
    partnerInfo && partnerInfo.name && partnerInfo.phoneNumber;

  const { push } = useFlow();
  const { mutate: putUser } = usePutUser({
    onError: (error) => {
      console.log(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [getMePath],
      });
    },
  });

  const renderPartnerInfo = () => {
    if (!hasPartnerInfo) {
      return "정보를 입력해주세요";
    }
    return partnerInfo.name;
  };

  const renderPartnerEndContents = () => {
    if (!hasPartnerInfo) {
      return (
        <EndContents>
          <div>입력하기</div>
          <img src={ChevronRightIcon} alt="right" width={16} height={16} />
        </EndContents>
      );
    }

    return (
      <EndContents
        style={{
          width: "max-content",
        }}
        onClick={() => {
          const result = confirm("정보를 삭제하시겠습니까?");
          if (!result) return;
          putUser({
            partner_name: null,
            partner_number: null,
          });
        }}
      >
        <div>삭제</div>
        <img src={TrashIcon} alt="delete" width={20} height={20} />
      </EndContents>
    );
  };

  return (
    <BoxButton
      onClick={
        hasPartnerInfo
          ? () => {}
          : () => {
              push("SetPartnerPhoneInfo", {
                mode: "edit",
              });
            }
      }
      prefixIcon={<Icon src={HeartIcon} />}
      contents={renderPartnerInfo()}
      subContents={hasPartnerInfo ? partnerInfo.phoneNumber : null}
      endContents={renderPartnerEndContents()}
    />
  );
}

export default PartnerInfoBoxButton;
