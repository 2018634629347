import styled from "@emotion/styled";

export const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0.75rem 1.5rem;
  box-sizing: border-box;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
  padding: 0 2rem;
  box-sizing: border-box;
`;
export const Title = styled.div`
  text-align: center;
  font-family: "SUIT-Bold";
  color: #ffffff;
  font-size: 1.5rem;
  line-height: 2.025rem;
  font-weight: 700;
  letter-spacing: -3%;
`;
export const Text = styled.div`
  text-align: center;
  font-family: "SUIT-Regular";
  color: #d4d0f4;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -3%;
`;

export const Image = styled.img`
  width: 150px;
  height: 150px;
`;

export const Step1Img = styled.img`
  width: 160px;
  height: auto;
`;

export const Button = styled.button`
  width: 100%;
  flex: 1;
  padding: 1rem 0;
  background-color: #0e0e11;
  color: #fff;
  border: none;
  border-radius: 3.5rem;
  font-family: "SUIT-Regular";
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  cursor: pointer;
`;
