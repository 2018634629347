import styled from "@emotion/styled";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #ffffff;
`;

export const Title = styled.div``;

export const SubTitle = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #d4d0f4;
  margin-top: 0.25rem;
`;

export const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  box-sizing: border-box;
  padding: 0 1.5rem;
`;
