import { SERVER_URL } from "@/config/path";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";

type CheckSomeRequest = {
  text: string;
};

type CheckSomeResponse = {
  success: boolean;
  message: string;
  result: string;
};

export const postCheckSomePath = `${SERVER_URL}/analyze-chat`;

export const postCheckSome = async (body: CheckSomeRequest) => {
  const { data } = await axios.post<
    CheckSomeRequest,
    AxiosResponse<CheckSomeResponse>
  >(postCheckSomePath, {
    ...body,
  });

  return data;
};

type Props = UseMutationOptions<CheckSomeResponse, Error, CheckSomeRequest>;

export const usePostCheckSome = ({ onError, onSuccess }: Props) => {
  return useMutation({
    mutationFn: async (body: CheckSomeRequest) => {
      const result = await postCheckSome(body);
      return result;
    },
    onError,
    onSuccess,
  });
};

export default usePostCheckSome;

const MOCK_RESULT = {
  message: "Chat analyzed successfully",
  result:
    '{\n  "percentage": "5%",\n  "is_sum": false,\n  "message": "// 대화를 통해서 분석해본 결과, 두 인물(엄마와 류찬규) 간의 대화는 일상적인 가족 간의 대화와 정보 교환을 중심으로 구성되어 있습니다. 이들이 서로에게 관심과 걱정을 표현하고는 있으나, 이는 가족 간의 일반적인 소통 방법일 뿐 서로에 대한 로맨틱한 감정이 느껴지지 않습니다. 상대방과의 물리적 거리나 궁극적으로 시간에 대한 언급이 많으며, 대화의 주제는 주로 일상적인 일이나 필요에 관한 것입니다. 따라서 이들은 썸 단계가 아니라 단순한 가족 관계에 있는 것으로 판단됩니다.\\n\\n사랑으로 발전하기 위해서는 서로의 삶에 더 많은 개입을 하거나 정서적인 지원을 제공할 기회를 늘려야 할 것입니다. 예를 들어, 서로의 관심사에 대해 더 자주 대화하고, 혹은 시간을 내어 서로의 취미 생활이나 기념일을 챙기는 방법도 좋습니다. 이러한 노력이 쌓이면 점차적으로 폭넓은 관계로 발전할 수 있을 것입니다."\n}',
  success: true,
};
