import React, { useEffect, useState } from "react";

export interface Message {
  sender: string;
  text: string;
}

const ChatBubbleItem = ({
  message,
  uniqueTalker,
  style,
}: {
  message: Message;
  uniqueTalker: string[];
  style?: React.CSSProperties;
}) => {
  const me = uniqueTalker[0];

  // 말풍선 스타일 결정
  const isUser = message.sender === me;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: isUser ? "row-reverse" : "row",
        alignItems: "center",
        marginBottom: "10px",
        ...style,
      }}
    >
      <div
        style={{
          maxWidth: "60%",
          padding: "10px",
          borderRadius: "15px",
          backgroundColor: isUser ? "#E0F7FA" : "#FFFFFF",
          border: isUser ? "1px solid #A3D4E0" : "1px solid #ddd",
          position: "relative",
          textAlign: isUser ? "right" : "left",
        }}
      >
        {!isUser && (
          <div style={{ fontSize: "12px", color: "#555", marginBottom: "5px" }}>
            {message.sender}
          </div>
        )}
        <div style={{ fontSize: "14px" }}>{message.text}</div>
      </div>
    </div>
  );
};

export default ChatBubbleItem;
