import styled from "@emotion/styled";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;
export const InputWrapper = styled.div<{ isError: boolean }>`
  padding: 14px 24px;
  border-radius: 54px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #6d5aec;
  box-sizing: border-box;
  border: 1px solid ${({ isError }) => (isError ? "#ff4d4f" : "transparent")};
`;

export const PrefixIconWrapper = styled.div`
  margin-right: 0.5rem;
`;

export const StyledInput = styled.input`
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: #ffffff;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -3%;

  &::placeholder {
    color: #d5d2f4;
  }
`;

export const ErrorText = styled.div`
  margin: 0.5rem 1rem;
  color: #ff4e47;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: -3%;
`;
