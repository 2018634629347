import { SERVER_URL } from "@/config/path";
import { NullableInt64, NullableString } from "@/types/common";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { fetchInstance } from "../instance";

export type MeResponse = {
  user: {
    id: number;
    kakao_id: string;
    kakao_email: string;
    kakao_profile_img: NullableString;
    my_phone_number: NullableInt64;
    partner_name: NullableString;
    partner_number: NullableInt64;
  };
  sendHint: {
    available: boolean;
    sent: boolean;
  };
  receiveHint: {
    hints?:
      | {
          message: string;
          createdAt: string;
        }[]
      | null;
  };
};

export const getMePath = `${SERVER_URL}/me`;

export const getMe = async () => {
  const jwt = window.sessionStorage.getItem("token");
  const { data } = await fetchInstance({ jwt: jwt ?? "" }).get<
    undefined,
    AxiosResponse<MeResponse>
  >(getMePath);

  return data;
};

export const useGetMe = () => {
  const { data, refetch, isLoading, status } = useQuery({
    queryKey: [getMePath],
    queryFn: getMe,
  });

  const response = data;

  return { data: response, refetch, isLoading, status };
};

export default useGetMe;
