import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export const fetchInstance = ({
  jwt,
  config,
}: {
  jwt?: string;
  config?: AxiosRequestConfig;
}): AxiosInstance => {
  const initFetchInstance = axios.create({
    timeout: 5000,
    ...config,
    headers: {
      Authorization: jwt,
      Accept: "application/json",
      ...config?.headers,
    },
  });

  // initFetchInstance({
  //   baseURL: API_ENDPOINT,
  // });

  return initFetchInstance;
};
