import BoxButton from "@components/common/BoxButton";

import TalkMessageIcon from "@/assets/talk_yellow.png";
import {
  Container,
  ContentsWrapper,
  PrefixIcon,
  TitleWrapper,
} from "./index.css";
import { useFlow } from "@/stackflow";
import { Spacing } from "@components/common/Spacing";
import FunIcon from "@assets/FunIcon";

// 재미있는 기능 섹션
function ToolSection() {
  const { push } = useFlow();
  const CheckSomePage = () => {
    // 힌트 보내기 페이지로 이동
    push("CheckSomePage", {});
  };

  return (
    <Container>
      <Spacing height={12} />
      <TitleWrapper>재미있는 기능</TitleWrapper>
      <ContentsWrapper>
        <BoxButton
          onClick={CheckSomePage}
          prefixIcon={<PrefixIcon src={TalkMessageIcon} />}
          contents={`대화내용으로 썸 확인하기`}
        />
      </ContentsWrapper>
    </Container>
  );
}

export default ToolSection;
