import { AppScreen } from "@/stackflow/components";
import { Spacing } from "@components/common/Spacing";
import guide_step1 from "@/assets/guide_step1.png";
import guide_step2 from "@/assets/guide_step2.png";
import guide_step3 from "@/assets/guide_step3.png";
import guide_step4 from "@/assets/guide_step4.png";
import guide_step5 from "@/assets/guide_step5.png";

import KakaoGuide1 from "@/assets/kakao_guide1.png";
import KakaoGuide2 from "@/assets/kakao_guide2.png";
import KakaoGuide3 from "@/assets/kakao_guide3.png";
import KakaoGuide4 from "@/assets/kakao_guide4.png";
import KakaoGuide5 from "@/assets/kakao_guide5.png";

import {
  Button,
  ButtonWrapper,
  Container,
  GuideImage,
  StemImg,
  Title,
  TitleWrapper,
} from "./index.css";
import React, { useCallback, useMemo, useState } from "react";
import { useFlow } from "@/stackflow";

function UploadTalkGuidePage() {
  const [step, setStep] = useState(0);
  const { pop } = useFlow();

  const renderData = useCallback((data: GuideData) => {
    return (
      <>
        <TitleWrapper>
          <StemImg src={data.stepImg} />
          <Spacing height={16} />
          <Title>{data.title}</Title>
        </TitleWrapper>

        <Spacing height={30} />
        <GuideImage src={data.guideImg} />
      </>
    );
  }, []);

  const renderStep = useCallback(() => {
    if (step < data.length) {
      return renderData(data[step]);
    }
  }, [renderData, step]);

  const isLastStep = useMemo(() => {
    return step === data.length - 1;
  }, [step]);

  const handleNext = () => {
    if (step < data.length - 1) {
      setStep(step + 1);
      return;
    }
    pop();
  };

  return (
    <AppScreen
      appBar={{
        iconColor: "#ffffff",
        title: (
          <div
            style={{
              color: "#ffffff",
            }}
          >
            대화내용 가져오기
          </div>
        ),
      }}
      accessoryBar={
        <ButtonWrapper>
          <Button onClick={handleNext}>
            {isLastStep ? "처음으로" : "다음"}
          </Button>
        </ButtonWrapper>
      }
    >
      <Container>{renderStep()}</Container>
    </AppScreen>
  );
}

export default UploadTalkGuidePage;

const data = [
  {
    stepImg: guide_step1,
    title: (
      <>
        카카오톡 앱을 켜서
        <br />
        채팅방을 선택해주세요
      </>
    ),
    guideImg: KakaoGuide1,
  },
  {
    stepImg: guide_step2,
    title: (
      <>
        채팅창 우측 상단의
        <br />
        메뉴 버튼을 눌러주세요
      </>
    ),
    guideImg: KakaoGuide2,
  },
  {
    stepImg: guide_step3,
    title: (
      <>
        메뉴 우측 하단의
        <br />
        설정 버튼을 눌러주세요
      </>
    ),
    guideImg: KakaoGuide3,
  },
  {
    stepImg: guide_step4,
    title: (
      <>
        &apos;대화내용 내보내기&apos;를
        <br />
        눌러주세요
      </>
    ),
    guideImg: KakaoGuide4,
  },
  {
    stepImg: guide_step5,
    title: (
      <>
        &apos;모든 메시지 내보내기&apos;를 눌러
        <br />
        대화 내용을 다운로드해 주세요
      </>
    ),
    guideImg: KakaoGuide5,
  },
];

type GuideData = {
  stepImg: string;
  title: React.ReactNode;
  guideImg: string;
};
