import styled from "@emotion/styled";
import { ActivityComponentType } from "@stackflow/react";

import { AppScreen } from "../../stackflow/components";
import { Container, Title, Text, Image, ButtonWrapper } from "./index.css";

import LogoMainImage from "../../assets/home_main.png";
import { Spacing } from "@components/common/Spacing";
import { useFlow } from "../../stackflow";
import SocialLoginButton from "./SocialLoginButton";
import { useMemo } from "react";
import { diffRemainDateToGoback } from "@utils/date";
import QuestionCircleIcon from "@assets/QuestionCircleIcon";
import { useEnterTrackEvent } from "@hooks/useEnterTrackEvent";
import { useQueryParams } from "@/stackflow/hooks/useQueryParams";
import { trackAmplitudeEvent } from "@hooks/useInitAmplitude";
import NextHomeButton from "./NextHomeButton";

const HomePage: ActivityComponentType = () => {
  const { push } = useFlow();

  const handleMoveToKakaoLogin = () => {
    push("SetMyPhoneInfoPage", {});
  };

  const { refer = "" } = useQueryParams();

  useEnterTrackEvent({
    event: "enter_home",
    params: {
      refer,
    },
  });

  const remainGobackDate = useMemo(() => diffRemainDateToGoback(), []);

  return (
    <AppScreen
      accessoryBar={
        <ButtonWrapper>
          <NextHomeButton />
          <SocialLoginButton />
        </ButtonWrapper>
      }
      appBar={{
        renderRight: () => (
          <QuestionCircleIcon
            color="white"
            onClick={() => {
              trackAmplitudeEvent("click_onboarding_question_home");
              push("OnBoardPage", {
                mode: "click",
              });
            }}
          />
        ),
      }}
    >
      <Container>
        <Image src={LogoMainImage} />
        <Spacing height={34} />
        <Title>고백 할까? 말까?</Title>
        <Spacing height={20} />
        <Text>고백데이까지 D-{remainGobackDate}일!</Text>
        <Text>지금 떠오르는 사람의 마음 확인해보세요</Text>
        <Spacing height={20} />
        <Text
          style={{
            color: "#F76EC9",
            fontWeight: 700,
          }}
        >
          🌟 내가 좋아하는 상대방이 나를 선택하지 않았다면
          <br />
          정보는 절대 공개되지 않아요
        </Text>
      </Container>
    </AppScreen>
  );
};

export default HomePage;
