import { useFlow } from "@/stackflow";
import { useQueryParams } from "@/stackflow/hooks/useQueryParams";
import { Spacing } from "@components/common/Spacing";
import { Modal } from "@stackflow/plugin-basic-ui";
import React, { useMemo } from "react";
import { Button, Container, InfoText, MainIcon } from "./index.css";

const SimpleAlertModal = () => {
  const { pop } = useFlow();
  const { headerIcon, message, buttonText = "확인" } = useQueryParams();

  const onClose = () => {
    pop();
  };

  const mainIcon = useMemo(() => {
    if (headerIcon === "sad") {
      return (
        <>
          <MainIcon>😢</MainIcon>
          <Spacing height={10} />
        </>
      );
    }
    return null;
  }, [headerIcon]);

  return (
    <Modal backgroundColor="#131023" dimBackgroundColor="rgba(0, 0, 0, 0.4)">
      <Container>
        {mainIcon}
        <InfoText>{message}</InfoText>
        <Spacing height={20} />
        <Button onClick={onClose}>{buttonText}</Button>
      </Container>
    </Modal>
  );
};

export default SimpleAlertModal;
