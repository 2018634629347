import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  flex: 1;
  box-sizing: border-box;
`;
export const RenderRightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  padding-right: 10px;

  color: white;
`;

export const TitleSection = styled.section`
  width: 100%;
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const LeftBox = styled.section`
  width: 100%;
`;
export const TopWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const ProfileImageWrapper = styled.div`
  position: relative;
  width: 72px;
  height: 72px;
`;

export const SettingIconWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SettingIconImg = styled.img`
  width: 13px;
  height: 13px;
`;

export const ProfileImage = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 50%;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.025rem;
  letter-spacing: -3%;

  .bold-date {
    font-family: "HSSanTokki20-Regular";
    font-weight: 500;
    color: #f76ec9;
  }
`;

export const SubTitle = styled.div`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #d4d0f4;
  margin-top: 1rem;
`;

export const Text = styled.div`
  text-align: center;
  font-family: "SUIT-Regular";
  color: #d4d0f4;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -3%;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0.75rem 1.5rem;
  box-sizing: border-box;
`;

export const Button = styled.button`
  width: 100%;
  flex: 1;
  padding: 1rem 0;
  background-color: #0e0e11;
  color: #fff;
  border: none;
  border-radius: 3.5rem;
  font-family: "SUIT-Regular";
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;
