import { useActivityParams } from "@stackflow/react";

/*
 * 서비스에서 사용하는 QueryParams를 정의합니다.
 */

export interface ActivityQueryParams {
  from?: string;
  title?: string;
  startDate?: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
  meetId?: string;
  nickname?: string;
  headerIcon?: string;
  message?: string;
  buttonText?: string;
  mode?: string;
  myNumber?: string;
  refer?: string;
  partnerName?: string;
  partnerNumber?: string;
  logined?: string;
  error?: string;
}

export const useQueryParams = (): ActivityQueryParams => {
  return useActivityParams<ActivityQueryParams>();
};
