import { enqueueSnackbar } from "notistack";

export const openDefaultSnackbar = (message: string, duration = 1000) => {
  enqueueSnackbar(message, {
    variant: "default",
    autoHideDuration: duration,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    preventDuplicate: true,
  });
};
