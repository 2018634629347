import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  padding: 1.5rem;
  box-sizing: border-box;
  background: #131023;
`;

export const MainIcon = styled.div`
  font-size: 2.5rem;
  line-height: 2.5rem;
`;

export const InfoText = styled.div`
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 700;
  color: #fff;
  word-break: keep-all;
`;

export const Button = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;

  background: #5943ea;
  color: #fff;
  border-radius: 3.5rem;

  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
`;
