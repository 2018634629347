import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
  padding: 0 2rem;
  box-sizing: border-box;
`;
export const Title = styled.div`
  text-align: center;
  font-family: "HS-Regular";
  color: #ffffff;
  font-size: 2.25rem;
  line-height: 3.125rem;
  letter-spacing: -3%;
`;
export const Text = styled.div`
  text-align: center;
  font-family: "SUIT-Regular";
  color: #d4d0f4;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -3%;
  word-break: keep-all;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0.75rem 1.5rem;
  box-sizing: border-box;
`;

export const Image = styled.img`
  width: 250px;
  height: auto;
`;
