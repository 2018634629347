import { forwardRef } from 'react';

interface Props {
  height?: number;
  background?: string;
}

export const Spacing = forwardRef(
  ({ height = 16, background }: Props, ref: React.Ref<HTMLDivElement>) => (
    <div
      ref={ref}
      style={{
        width: '100%',
        height: `${height}px`,
        minHeight: `${height}px`,
        background: background || 'transparent',
      }}
    />
  )
);
