import { useFlow } from "@/stackflow";
import { Spacing } from "@components/common/Spacing";
import { Modal } from "@stackflow/plugin-basic-ui";
import React from "react";
import {
  Button,
  Container,
  InfoText,
  MainIcon,
} from "./NeedPartnerPhoneNumberModal.css";

const NeetPartnerPhoneNumberModal = () => {
  const { pop } = useFlow();

  const onClose = () => {
    pop();
  };
  return (
    <Modal backgroundColor="#131023" dimBackgroundColor="rgba(0, 0, 0, 0.4)">
      <Container>
        <MainIcon>😢</MainIcon>
        <Spacing height={10} />
        <InfoText>상대방의 휴대폰 번호가 있어야 마음을 전할 수 있어요</InfoText>
        <Spacing height={20} />
        <Button onClick={onClose}>확인</Button>
      </Container>
    </Modal>
  );
};

export default NeetPartnerPhoneNumberModal;
