export const ROUTE_PATHS = {
  HOME: "/home",
  AUTH: "/auth",
  SET_MY_PHONE_INFO: "/set-my-phone-info",
  SET_PARTNER_PHONE_INFO: "/set-my-some",
  SEND_DONE: "/send-done",
  MODAL: {
    NEED_PARTNER_PHONE_NUMBER: "/modal/need-partner-phone-number",
    SIMPLE_ALERT: "/modal/alert",
  },
  MyPage: "/my-page",
  SEND_HINT: "/send-hint",
  SettingPage: "/setting",
  CheckSomePage: "/tool/check-some",
  UploadTalkGuidePage: "/guide/upload-talk-guide",
  OnBoardPage: "/on-board",
  ShareTemplatePage: "/share-template",
};

export const buildRoutePath = {
  BottomSheet: {},
};
