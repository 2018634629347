import { ReactElement, useEffect } from "react";
import { SnackbarProvider } from "notistack";

import { Stack } from "./stackflow";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import useInitAmplitude from "@hooks/useInitAmplitude";

export const queryClient = new QueryClient();

function App(): ReactElement {
  useInitAmplitude();

  return (
    <SnackbarProvider>
      <QueryClientProvider client={queryClient}>
        <Stack />
      </QueryClientProvider>
    </SnackbarProvider>
  );
}

export default App;
