import React from "react";

interface IconProps {
  size?: number;
  color?: string;
  onClick?: () => void;
}

const QuestionCircleIcon: React.FC<IconProps> = ({
  size = 24,
  color = "#000",
  onClick,
}) => {
  return (
    <svg
      onClick={onClick}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" stroke={color} strokeWidth="2" />
      <path
        d="M12 8C13.1046 8 14 8.89543 14 10C14 11.1046 13.1046 12 12 12V14"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="12" cy="17" r="1" fill={color} />
    </svg>
  );
};

export default QuestionCircleIcon;
