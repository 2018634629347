import { AppScreen } from "@/stackflow/components";
import { ActivityComponentType } from "@stackflow/react";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import {
  ButtonWrapper,
  Container,
  Step1Img,
  Title,
  Text,
  Button,
} from "./index.css";
import Step1Image from "@/assets/onboard_1.png";
import Step2Image from "@/assets/onboard_2.png";
import { Spacing } from "@components/common/Spacing";
import { useFlow } from "@/stackflow";
import { useQueryParams } from "@/stackflow/hooks/useQueryParams";
import { trackAmplitudeEvent } from "@hooks/useInitAmplitude";

const OnBoardPage: ActivityComponentType = () => {
  const [step, setStep] = React.useState(0);
  const { mode } = useQueryParams();
  const { replace, pop } = useFlow();

  const renderStep1 = useMemo(() => {
    return (
      <>
        <Step1Img src={Step1Image} />
        <Spacing height={74} />
        <Title>9월 17일은 고백데이!</Title>
        <Spacing height={8} />
        <Text>고백데이에 사귀면 크리스마스에 100일을 맞이해요</Text>
      </>
    );
  }, []);

  const renderStep2 = useMemo(() => {
    return (
      <>
        <Step1Img src={Step2Image} />
        <Spacing height={74} />
        <Title>
          내가 좋아하는 상대의 마음을
          <br />
          고백데이 당일에 알려드려요
        </Title>
        <Spacing height={8} />
        <Text>
          서로를 지목해서 매칭에 성공하면
          <br />
          고백데이 당일에 알려드려요
        </Text>
      </>
    );
  }, []);

  const render = useCallback(() => {
    trackAmplitudeEvent("click_onboarding_next", {
      step: step + 1,
    });

    if (step === 0) {
      return renderStep1;
    } else if (step === 1) {
      return renderStep2;
    }
  }, [renderStep1, renderStep2, step]);

  const handleNext = () => {
    if (step === 0) {
      setStep(1);
    } else if (step === 1) {
      if (mode === "click") {
        pop();
        return;
      }
      replace("HomePage", {});
    }
  };

  const setLocalstorageRef = useRef<boolean>(false);

  useEffect(() => {
    if (setLocalstorageRef.current === true) return;

    const localstorage = localStorage.getItem("onboard");

    if (localstorage === "true") {
      // 홈에서 직접 이동한 경우에는 replace하지 않음
      if (mode === "click") return;

      replace("HomePage", {}, { animate: false });
    } else {
      localStorage.setItem("onboard", "true");
    }
    setLocalstorageRef.current = true;
  }, [mode, replace]);

  return (
    <AppScreen
      appBar={{
        iconColor: "#ffffff",
      }}
      accessoryBar={
        <ButtonWrapper>
          <Button onClick={handleNext}>다음</Button>
        </ButtonWrapper>
      }
    >
      <Container>{render()}</Container>
    </AppScreen>
  );
};

export default OnBoardPage;
