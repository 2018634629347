import useGetAuthKakao from "@/apis/hooks/useGetAuthKakao";
import usePutUser from "@/apis/hooks/usePutUser";
import { queryClient } from "@/App";
import { SERVER_URL } from "@/config/path";
import { useFlow } from "@/stackflow";
import { AppScreen } from "@/stackflow/components";
import { useQueryParams } from "@/stackflow/hooks/useQueryParams";
import Spinner from "@components/common/Spinner";
import { useActivity } from "@stackflow/react";

import { openDefaultSnackbar } from "@utils/toast";
import axios from "axios";

import { useCallback, useEffect, useRef } from "react";
import { InnerWrapper, LoginText, Wrapper } from "./index.css";

function AuthPage() {
  const code = new URL(window.location.href).searchParams.get("code");

  const { error } = useQueryParams();
  const { replace } = useFlow();

  const { isTop } = useActivity();
  const { data, status } = useGetAuthKakao({ code });

  const { mutate: putUser } = usePutUser({
    onError: (error) => {
      console.log(error);
    },
    onSuccess: () => {
      window.sessionStorage.removeItem("myNumber");
      window.sessionStorage.removeItem("partnerName");
      window.sessionStorage.removeItem("partnerNumber");
      replace("SendDone", {});
    },
  });

  useEffect(() => {
    if (error && error.length !== 0) {
      replace(
        "HomePage",
        {},
        {
          animate: false,
        }
      );
      openDefaultSnackbar("로그인에 실패했습니다. 다시 시도해주세요.", 1000);
    }
  }, [error, replace]);

  useEffect(() => {
    if (status == "error") {
      replace(
        "HomePage",
        {},
        {
          animate: false,
        }
      );
      openDefaultSnackbar("로그인에 실패했습니다. 다시 시도해주세요.", 1000);
    }
    if (data && data.token) {
      window.sessionStorage.setItem("token", data.token);
    }
    if (data) {
      const myNumber = window.sessionStorage.getItem("myNumber");
      const partnerName = window.sessionStorage.getItem("partnerName");
      const partnerNumber = window.sessionStorage.getItem("partnerNumber");
      // 0. 파라미터로 전달된 번호가 있는 경우 - 변경된 플로우/ 정보 입력 완료 후 가입 플로우
      if (myNumber) {
        putUser({
          my_phone_number: Number(myNumber),
          ...(partnerName && partnerNumber
            ? {
                partner_name: partnerName,
                partner_number: Number(partnerNumber),
              }
            : {}),
        });

        return;
      }

      // 1.  내 번호 입력 여부 체크
      if (data.my_phone_number.Valid === false) {
        replace(
          "SetMyPhoneInfoPage",
          {
            logined: true,
          },
          {
            animate: false,
          }
        );
        return;
      }

      // 2. 파트너 번호 입력 여부 체크
      if (data.partner_number.Valid === false) {
        replace(
          "SetPartnerPhoneInfo",
          {
            logined: true,
          },
          { animate: false }
        );
        return;
      }

      // 3. 내 번호, 파트너 번호 입력 완료 - 마이페이지로 이동
      replace(
        "MyPage",
        {},
        {
          animate: false,
        }
      );
      return;
    }
  }, [data, putUser, replace, status]);

  return (
    <AppScreen noAppBar>
      <Wrapper>
        <InnerWrapper>
          <LoginText>로그인 중입니다...</LoginText>
          <Spinner color="white" />
        </InnerWrapper>
      </Wrapper>
    </AppScreen>
  );
}

export default AuthPage;
