import BoxButton from "@components/common/BoxButton";
import React, { useMemo } from "react";

import ChevronRightIcon from "@/assets/chevron_right.png";
import HintKeyIcon from "@/assets/hint_key_icon.png";
import {
  Container,
  ContentsWrapper,
  EndContents,
  SendHintWrapper,
  SubText,
  TitleWrapper,
} from "./index.css";
import PartnerInfoBoxButton from "./PartnerInfoBoxButton";
import { useFlow } from "@/stackflow";
import { MeResponse } from "@/apis/hooks/useGetMe";

type Props = {
  meData: MeResponse;

  sendHint: boolean;
  canSendHint: boolean;
};

// 마음을 전할 상대 섹션
function PartnerInfoSection({ meData, canSendHint, sendHint }: Props) {
  const { push } = useFlow();
  const handleSendHint = () => {
    // 힌트 보내기 페이지로 이동
    push("SendHintPage", {});
  };

  const partnerInfo = useMemo(() => {
    return {
      name: meData.user.partner_name.Valid
        ? meData.user.partner_name.String
        : " ",
      phoneNumber: meData.user.partner_number.Valid
        ? "0" + meData.user.partner_number.Int64
        : undefined,
    };
  }, [
    meData.user.partner_name.String,
    meData.user.partner_name.Valid,
    meData.user.partner_number.Int64,
    meData.user.partner_number.Valid,
  ]);

  const openHintFeature = meData.user.partner_name.Valid;

  return (
    <Container>
      <TitleWrapper>마음을 전할 상대</TitleWrapper>
      <ContentsWrapper>
        <PartnerInfoBoxButton partnerInfo={partnerInfo} />

        {/* 힌트 기능 오픈 여부 */}
        {openHintFeature && (
          <>
            {sendHint && (
              <BoxButton
                prefixIcon={<img src={HintKeyIcon} width={24} height={24} />}
                contents={`${partnerInfo?.name}님에게 힌트를 보냈어요`}
              />
            )}
            {!sendHint && canSendHint && (
              <BoxButton
                onClick={handleSendHint}
                prefixIcon={<img src={HintKeyIcon} width={24} height={24} />}
                contents={
                  <SendHintWrapper>
                    <div>{partnerInfo?.name}님에게 힌트 보내기</div>
                    <SubText>오늘만 주어진 기회 놓치지 마세요</SubText>
                  </SendHintWrapper>
                }
                endContents={
                  <EndContents>
                    <img
                      src={ChevronRightIcon}
                      alt="right"
                      width={16}
                      height={16}
                    />
                  </EndContents>
                }
              />
            )}
          </>
        )}
      </ContentsWrapper>
    </Container>
  );
}

export default PartnerInfoSection;
