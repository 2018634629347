import { ActivityComponentType } from "@stackflow/react";

import { AppScreen } from "../../stackflow/components";
import {
  Button,
  Container,
  Title,
  Text,
  Image,
  ButtonWrapper,
} from "./index.css";

import PopOverRightIcon from "../../assets/popover_right.png";
import { Spacing } from "@components/common/Spacing";
import { useFlow } from "../../stackflow";
import Input from "@components/common/Input";
import { useState } from "react";
import usePutUser from "@/apis/hooks/usePutUser";
import { useQueryParams } from "@/stackflow/hooks/useQueryParams";
import { queryClient } from "@/App";
import { getMePath } from "@/apis/hooks/useGetMe";
import useActiveActivities from "@/stackflow/hooks/useActiveActivities";
import { useEnterTrackEvent } from "@hooks/useEnterTrackEvent";
import { NotiText } from "@components/SetPartnerPhoneInfo/index.css";

const SetMyPhoneInfoPage: ActivityComponentType = () => {
  const { push, pop, replace } = useFlow();

  const { mode, myNumber, logined } = useQueryParams();
  useEnterTrackEvent({
    event: "enter_set_my_phone_info",
    params: {
      mode,
      logined,
    },
  });
  const { isExistSpecificActivities } = useActiveActivities();

  const defaultPhoneNumber = myNumber || "010";
  const [phoneNumber, setPhoneNumber] = useState(defaultPhoneNumber);
  const [phoneNumberError, setPhoneNumberError] = useState({
    isError: false,
    message: "",
  });

  const { mutate: putUser } = usePutUser({
    onError: (error) => {
      console.log(error);
    },
    onSuccess: () => {
      if (mode === "edit") {
        setTimeout(() => {
          queryClient.refetchQueries({
            queryKey: [getMePath],
          });
        }, 500);

        const isExistBack = isExistSpecificActivities("SettingPage");

        if (isExistBack) {
          pop();
          return;
        }
        replace("MyPage", {});
        return;
      }
      push("SetPartnerPhoneInfo", {
        myNumber: phoneNumber,
        logined,
      });
    },
  });

  const handleMoveToSetPartnerPhoeInfo = async () => {
    if (phoneNumber.length !== 11) {
      setPhoneNumberError({
        isError: true,
        message: "휴대폰 번호를 다시 확인해주세요.",
      });

      return;
    }

    if (mode === "edit") {
      putUser({
        my_phone_number: Number(phoneNumber),
      });
      return;
    }

    push("SetPartnerPhoneInfo", {
      myNumber: phoneNumber,
      logined,
    });
  };

  const handleOnChangePhoneNumber = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value.length === 11) {
      setPhoneNumberError({
        isError: false,
        message: "",
      });
    }
    if (e.target.value.length > 11) return;
    setPhoneNumber(e.target.value);
  };

  const accesoryBarButtonText =
    mode === "edit" ? "수정하기" : "상대방 마음 확인하기";

  return (
    <AppScreen
      appBar={{
        iconColor: "#ffffff",
      }}
      accessoryBar={
        <ButtonWrapper>
          <Button onClick={handleMoveToSetPartnerPhoeInfo}>
            {accesoryBarButtonText}
          </Button>
        </ButtonWrapper>
      }
    >
      <Container>
        <Spacing height={24} />
        <Image src={PopOverRightIcon} />
        <Spacing height={12} />
        <Title>
          본인 휴대폰 번호를 <wbr />
          입력해주세요
        </Title>
        <Spacing height={8} />
        <Text>본인 휴대폰 번호를 설정해야</Text>
        <Text>상대와의 매칭 성공 여부를 알 수 있어요</Text>
        <Spacing height={48} />
        <Input
          prefixIcon={"📱"}
          placeholder="휴대폰 번호를 입력해주세요"
          type={"number"}
          inputMode="numeric"
          max={11}
          maxLength={11}
          onChange={handleOnChangePhoneNumber}
          value={phoneNumber}
          isError={phoneNumberError.isError}
          errorMessage={phoneNumberError.message}
        />
        <Spacing height={30} />
        <NotiText
          style={{
            color: "#F76EC9",
            fontWeight: 700,
          }}
        >
          🌟 내가 좋아하는 상대방과 매칭되지 않는다면
          <br />
          정보는 어디에도 절대 공개되지 않아요 🌟
        </NotiText>
      </Container>
    </AppScreen>
  );
};

export default SetMyPhoneInfoPage;
