import React from "react";
import {
  ErrorText,
  InputWrapper,
  PrefixIconWrapper,
  StyledInput,
  Wrapper,
} from "./index.css";

type Props = {
  prefixIcon?: React.ReactNode | string;
  isError?: boolean;
  errorMessage?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Input = ({
  prefixIcon,
  isError = false,
  errorMessage,
  ...props
}: Props) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <Wrapper
      onClick={() => {
        if (inputRef.current) inputRef.current.focus();
      }}
    >
      <InputWrapper isError={isError}>
        {prefixIcon && <PrefixIconWrapper>{prefixIcon}</PrefixIconWrapper>}
        <StyledInput {...props} ref={inputRef} />
      </InputWrapper>
      {isError && <ErrorText>{errorMessage}</ErrorText>}
    </Wrapper>
  );
};

export default Input;
