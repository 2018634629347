import { CLIENT_URL } from "@/config/path";
import { AppScreen } from "@/stackflow/components";
import CopyIcon from "@assets/CopyIcon";
import BoxButton from "@components/common/BoxButton";
import { Spacing } from "@components/common/Spacing";
import { trackAmplitudeEvent } from "@hooks/useInitAmplitude";

import { copyToClipboard } from "@utils/link";
import React from "react";
import {
  Container,
  ContentsText,
  InfoText,
  RowContainer,
  Text,
} from "./index.css";

const ShareTemplatePage = () => {
  const renderShareEndContents = () => {
    return (
      <RowContainer>
        <CopyIcon size={15} />
        <div>복사</div>
      </RowContainer>
    );
  };

  const handleShareLink = async ({
    title,
    text,
  }: {
    title: string;
    text: string;
  }) => {
    if (typeof navigator.share === "undefined") {
      // 공유하기 버튼을 지원하지 않는 경우에 대한 폴백 처리
      copyToClipboard(`${title}
${text}
${CLIENT_URL}`);
      return;
    }
    try {
      await navigator.share({
        title: title,
        text: text,
        url: CLIENT_URL,
      });
    } catch (error) {
      copyToClipboard(`${title}
${text}
${CLIENT_URL}`);
    }
  };

  return (
    <AppScreen
      appBar={{
        iconColor: "#ffffff",
        title: (
          <div
            style={{
              color: "#ffffff",
            }}
          >
            링크 공유하기
          </div>
        ),
      }}
    >
      <Container>
        <InfoText
          style={{
            textAlign: "center",
            fontSize: 15,
            fontWeight: 700,
          }}
        >
          <div
            style={{
              backgroundColor: "#9b48e3",
              width: "fit-content",
              margin: "auto",
              padding: "0 5px",
              borderRadius: 5,
              marginBottom: "5px",
            }}
          >
            TIP
          </div>
          짝사랑이 같이 있는 단톡방에 은근슬쩍 공유하면
          <br />
          매칭 확률이 높아질 수 있어요.
        </InfoText>

        <Spacing height={30} />
        <InfoText>
          아래 템플릿을 클릭해 복사하거나 카카오톡, 문자 메시지 등으로 쉽게
          공유할 수 있어요.
        </InfoText>
        <Spacing height={20} />
        <Text>기본 Ver.</Text>
        <Spacing height={8} />
        <BoxButton
          onClick={() => {
            trackAmplitudeEvent("click_share_template", {
              template: 1,
            });
            handleShareLink({
              title: "고백 할까? 말까?",
              text: "짝남/짝녀 진짜 마음 확인하기",
            });
          }}
          direction="column"
          contents={
            <ContentsText>
              고백 할까? 말까?
              <br />
              짝사랑 진짜 마음 확인하기
            </ContentsText>
          }
          subContents={"https://go-back.me"}
          endContents={renderShareEndContents()}
          gap={4}
        />
        <Spacing height={26} />

        <Text>찐친 Ver.</Text>
        <Spacing height={8} />
        <BoxButton
          onClick={() => {
            trackAmplitudeEvent("click_share_template", {
              template: 2,
            });
            handleShareLink({
              title: "나 이번 크리스마스에 너희랑 못 논다ㅋㅋㅋㅋ",
              text: "https://go-back.me",
            });
          }}
          direction="column"
          contents={
            <ContentsText>
              나 이번 크리스마스에 너희랑 못 논다ㅋㅋㅋㅋ
            </ContentsText>
          }
          subContents={"https://go-back.me"}
          endContents={renderShareEndContents()}
          gap={4}
        />
        <Spacing height={8} />
        <BoxButton
          onClick={() => {
            trackAmplitudeEvent("click_share_template", {
              template: 3,
            });
            handleShareLink({
              title: "나 안 차이고 고백하는 법 알아냄",
              text: "https://go-back.me",
            });
          }}
          direction="column"
          contents={
            <ContentsText>나 안 차이고 고백하는 법 알아냄</ContentsText>
          }
          subContents={"https://go-back.me"}
          endContents={renderShareEndContents()}
          gap={4}
        />
        <Spacing height={26} />
      </Container>
    </AppScreen>
  );
};

export default ShareTemplatePage;
