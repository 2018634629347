import { parseMessages } from "@utils/kakaoTalkMessage";
import React, { useEffect, useMemo, useState } from "react";
import ChatBubbleItem, { Message } from "./ChatBubbleItem";

const ChatBubbleList = ({ messages }: { messages: Message[] }) => {
  const [index, setIndex] = useState(0);

  const [uniqueTalker, setUniqueTalker] = useState<string[]>([]);

  // uniqueTalker
  useEffect(() => {
    const talkers = new Set<string>();
    messages.forEach((message) => {
      talkers.add(message.sender);
    });
    setUniqueTalker(Array.from(talkers));
  }, [messages]);

  // console.log("messages3", messages);
  // const messages = useMemo(() => parseMessages(fileContent), [fileContent]);

  useEffect(() => {
    console.log("messages3", messages);
    if (index < messages.length - 1) {
      const timer = setTimeout(() => {
        setIndex((prev) => prev + 1);
      }, 2500);

      return () => clearTimeout(timer); // 컴포넌트가 언마운트되면 타이머를 클리어
    }
  }, [index, messages]);

  return (
    <div
      style={{
        width: "calc(100% - 40px)",
        margin: "10px 20px",
        overflowY: "hidden",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        position: "relative",
        transition: "all 0.5s",
      }}
    >
      {messages.map((message, idx) => (
        <ChatBubbleItem
          key={idx}
          uniqueTalker={uniqueTalker}
          message={message}
          style={{
            width: "100%",
            position: "absolute",
            top: idx === index ? 0 : idx < index ? "-200%" : "100%",
            opacity: idx === index ? 1 : idx < index ? "0" : "0.4",
            transition: "all 0.8s",
          }}
        />
      ))}

      {/* relative 외부 div 사이즈 측정용 */}
      {messages.map((message, idx) => (
        <ChatBubbleItem
          key={idx}
          uniqueTalker={uniqueTalker}
          message={message}
          style={{
            opacity: 0,
            width: "100%",
            top: 0,
            left: 0,
            position: idx === index ? "relative" : "absolute",
            transition: "all 0.5s",
          }}
        />
      ))}
    </div>
  );
};

export default ChatBubbleList;
