import { AppScreen } from "@/stackflow/components";
import { Spacing } from "@components/common/Spacing";
import { ActivityComponentType } from "@stackflow/react";
import PopOverKeyRightIcon from "@/assets/popover_right_key.png";

import SelectedHintCircleIcon from "@/assets/selected_hint_circle.svg";
import UnSelectedHintCircleIcon from "@/assets/unselected_hint_circle.svg";

import {
  Container,
  Title,
  Text,
  Image,
  HitListGrid,
  HintItem,
  HintItemText,
  ButtonWrapper,
  Button,
  SelectedCircleIconImg,
} from "./index.css";

import { useState } from "react";
import { useFlow } from "@/stackflow";
import useActiveActivities from "@/stackflow/hooks/useActiveActivities";
import usePutUser from "@/apis/hooks/usePutUser";
import { queryClient } from "@/App";
import { getMePath } from "@/apis/hooks/useGetMe";

const SendHintPage: ActivityComponentType = () => {
  const { push, pop, replace } = useFlow();
  const [selectedHint, setSelectedHint] = useState<string | null>(null);
  const { diffActivitiesFromCurrent } = useActiveActivities();

  const { mutate: putUser } = usePutUser({
    onError: (error) => {
      console.log(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [getMePath],
      });
    },
  });

  const handleSubmit = () => {
    if (selectedHint === null) {
      push("SimpleAlertModal", {
        message: "힌트를 선택해주세요",
      });
      return;
    }

    // TODO: 힌트 보내기 API
    // TODO: onSuccess에서 me 페이지 데이터 refetch 처리

    putUser({
      hint_message: selectedHint,
    });

    const diffToMyPage = diffActivitiesFromCurrent("MyPage");
    if (diffToMyPage !== -1) {
      pop(diffToMyPage);
      return;
    }

    // 예상 외의 플로우인 경우 일단 push
    replace("MyPage", {});
  };

  return (
    <AppScreen
      appBar={{
        iconColor: "#ffffff",
      }}
      accessoryBar={
        <ButtonWrapper>
          <Button onClick={handleSubmit}>힌트 보내기</Button>
        </ButtonWrapper>
      }
    >
      <Container>
        <Spacing height={24} />
        <Image src={PopOverKeyRightIcon} />
        <Spacing height={12} />
        <Title>{questionSet.question}</Title>
        <Spacing height={8} />
        <Text>힌트는 내일 상대가 열어볼 수 있어요</Text>
        <Spacing height={48} />
        <HitListGrid>
          {questionSet.itemList.map((item) => {
            const isSelected = selectedHint === item.text;
            return (
              <HintItem
                key={item.id}
                onClick={() => {
                  setSelectedHint(item.text);
                }}
                isSelected={isSelected}
              >
                <SelectedCircleIconImg
                  src={
                    isSelected
                      ? SelectedHintCircleIcon
                      : UnSelectedHintCircleIcon
                  }
                />
                <HintItemText>{item.text}</HintItemText>
              </HintItem>
            );
          })}
        </HitListGrid>
      </Container>
    </AppScreen>
  );
};

export default SendHintPage;

export const questionSet = {
  question: "나를 나타내는 물건은?",
  itemList: [
    {
      id: 1,
      text: "키링",
      imageUrl: "https://picsum.photos/100/100",
    },
    {
      id: 2,
      text: "머그컵",
      imageUrl: "https://picsum.photos/100/100",
    },
    {
      id: 3,
      text: "포스트잇",
      imageUrl: "https://picsum.photos/100/100",
    },
    {
      id: 4,
      text: "휴대폰",
      imageUrl: "https://picsum.photos/100/100",
    },
    {
      id: 5,
      text: "지갑",
      imageUrl: "https://picsum.photos/100/100",
    },
    {
      id: 6,
      text: "펜",
      imageUrl: "https://picsum.photos/100/100",
    },
  ],
};
