import BoxButton from "@components/common/BoxButton";
import HintKeyIcon from "@/assets/hint_key_icon.png";
import HintLockIcon from "@/assets/hint_lock_icon.png";
import { MeResponse } from "@/apis/hooks/useGetMe";
import { questionSet } from "@components/SendHintPage";
import { useMemo } from "react";

type HintItem = NonNullable<MeResponse["receiveHint"]["hints"]>[0];

type Props = { item: HintItem };

function HintBoxButton({ item }: Props) {
  // 힌트는 생성 후 24시간 이내에는 열람할 수 없음
  const isPassed24Hours = useMemo(() => {
    const now = new Date();
    const createdAtDate = new Date(item.createdAt);
    const diff = now.getTime() - createdAtDate.getTime();
    return diff > 24 * 60 * 60 * 1000;
  }, [item.createdAt]);

  if (!isPassed24Hours) {
    return (
      <BoxButton
        prefixIcon={
          <img src={HintLockIcon} alt="hint" width={24} height={24} />
        }
        contents={"힌트를 받고 24시간이 지나야 열 수 있어요"}
      />
    );
  }

  // if (item.opened) {
  //   return (
  //     <BoxButton
  //       prefixIcon={<img src={HintKeyIcon} alt="hint" width={24} height={24} />}
  //       contents={item.question}
  //       subContents={item.answer}
  //     />
  //   );
  // }

  return (
    <BoxButton
      prefixIcon={<img src={HintKeyIcon} alt="hint" width={24} height={24} />}
      contents={questionSet.question}
      subContents={item.message}
    />
  );

  // return (
  //   <BoxButton
  //     prefixIcon={<img src={HintKeyIcon} alt="hint" width={24} height={24} />}
  //     contents={"힌트가 도착했어요!"}
  //     endContents={
  //       <EndContents>
  //         <div>열어보기</div>
  //         <img src={ChevronRightIcon} alt="right" width={16} height={16} />
  //       </EndContents>
  //     }
  //   />
  // );
}

export default HintBoxButton;
