import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  flex: 1;
  padding: 1rem 2rem;
  box-sizing: border-box;
`;
export const Title = styled.div`
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: -3%;
  margin-bottom: 1rem;
`;
export const Text = styled.div`
  text-align: center;
  color: #d4d0f4;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -3%;
`;

export const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;
`;
