import styled from "@emotion/styled";

export const Wrapper = styled.div`
  position: fixed;
  margin: auto;
  width: 100%;
  // maxWidth: 100%;
  transform: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
`;

export const InnerWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, #5943ea 33.65%, #3822bf 100%);
  margin-left: 0;
  margin-right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
`;
export const LoginText = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
`;
