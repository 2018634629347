import { useEffect, useRef } from "react";
import { SpecificEventType } from "./event";

import { trackAmplitudeEvent } from "./useInitAmplitude";

type Props = {
  event: SpecificEventType;
  params?: any;
};

export const useEnterTrackEvent = ({ event, params }: Props) => {
  // enter event 2번 발생 제어
  const loggedEnter = useRef(false);

  useEffect(() => {
    if (loggedEnter.current) return;

    loggedEnter.current = true;
    trackAmplitudeEvent(event, params);
  }, [event, params]);
};
