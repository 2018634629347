import "../style/App.css";
import { useActions } from "@stackflow/react";
import { useStepActions } from "@stackflow/react";
import { stackflow } from "@stackflow/react";
import { historySyncPlugin } from "@stackflow/plugin-history-sync";
import { basicRendererPlugin } from "@stackflow/plugin-renderer-basic";

import { basicUIPlugin } from "@stackflow/plugin-basic-ui";

import "@stackflow/plugin-basic-ui/index.css";
import { ROUTE_PATHS } from "./routes";
import HomePage from "../components/Home";
import SetMyPhoneInfoPage from "@components/SetMyPhoneInfo";
import SetPartnerPhoneInfo from "@components/SetPartnerPhoneInfo";
import NeedPartnerPhoneNumberModal from "@components/SetPartnerPhoneInfo/NeedPartnerPhoneNumberModal";
import SendDone from "@components/SendDone";
import MyPage from "@components/MyPage";
import SendHintPage from "@components/SendHintPage";
import SimpleAlertModal from "@components/SimpleAlertModal";
import AuthPage from "@components/AuthPage";
import { SettingPage } from "@components/SettingPage";
import CheckSomePage from "@components/CheckSomePage";
import UploadTalkGuidePage from "@components/UploadTalkGuidePage";
import OnBoardPage from "@components/OnBoardPage";
import ShareTemplatePage from "@components/ShareTemplatePage";

const initStackflow = () => {
  return stackflow({
    transitionDuration: 350,
    activities: {
      HomePage,
      SetMyPhoneInfoPage,
      SetPartnerPhoneInfo,
      NeedPartnerPhoneNumberModal,
      SendDone,
      MyPage,
      SendHintPage,
      SimpleAlertModal,
      AuthPage,
      SettingPage,
      CheckSomePage,
      UploadTalkGuidePage,
      OnBoardPage,
      ShareTemplatePage,
    },
    plugins: [
      historySyncPlugin({
        routes: {
          HomePage: ROUTE_PATHS.HOME,
          SetMyPhoneInfoPage: ROUTE_PATHS.SET_MY_PHONE_INFO,
          SetPartnerPhoneInfo: ROUTE_PATHS.SET_PARTNER_PHONE_INFO,
          NeedPartnerPhoneNumberModal:
            ROUTE_PATHS.MODAL.NEED_PARTNER_PHONE_NUMBER,
          SendDone: ROUTE_PATHS.SEND_DONE,
          MyPage: ROUTE_PATHS.MyPage,
          SendHintPage: ROUTE_PATHS.SEND_HINT,
          SimpleAlertModal: ROUTE_PATHS.MODAL.SIMPLE_ALERT,
          AuthPage: ROUTE_PATHS.AUTH,
          SettingPage: ROUTE_PATHS.SettingPage,
          CheckSomePage: ROUTE_PATHS.CheckSomePage,
          UploadTalkGuidePage: ROUTE_PATHS.UploadTalkGuidePage,
          OnBoardPage: ROUTE_PATHS.OnBoardPage,
          ShareTemplatePage: ROUTE_PATHS.ShareTemplatePage,
        },
        fallbackActivity: () => {
          return "OnBoardPage";
        },
      }),
      basicRendererPlugin(),
      basicUIPlugin({ theme: "cupertino" }),
    ],
  });
};

const {
  Stack,
  activities,
  useFlow: useOriginFlow,
  useStepFlow: useOriginStepFlow,
} = initStackflow();

export type TypeActivities = typeof activities;
export type TypeActivityNames = keyof TypeActivities;
export type TypeUseFlow = typeof useOriginFlow;
export type TypeUseStepFlow = typeof useOriginStepFlow;

const useFlow: TypeUseFlow = () => useActions<TypeActivities>();
const useStepFlow: TypeUseStepFlow = (activityName: TypeActivityNames) =>
  useStepActions(activityName as never);

export { Stack, activities, useFlow, useStepFlow };
