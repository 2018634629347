import useDeleteUser from "@/apis/hooks/useDeleteUser";
import useGetMe from "@/apis/hooks/useGetMe";

import { useFlow } from "@/stackflow";
import { AppScreen } from "@/stackflow/components";
import BoxButton from "@components/common/BoxButton";
import { Container, List } from "./index.css";
import ChevronRightIcon from "@/assets/chevron_right.png";
import { useEnterTrackEvent } from "@hooks/useEnterTrackEvent";
import { trackAmplitudeEvent } from "@hooks/useInitAmplitude";

export const SettingPage = () => {
  const { push, pop, replace } = useFlow();
  const { data: meData } = useGetMe();

  useEnterTrackEvent({
    event: "enter_setting_page",
  });

  const { mutate: deleteUser } = useDeleteUser({
    onError: (error) => {
      console.log(error);
    },
    onSuccess: () => {
      pop(100);
      replace("HomePage", {});
    },
  });

  const handleEditMyPhoneNumber = () => {
    const myPhoneNumber = meData?.user.my_phone_number.Valid
      ? "0" + meData?.user.my_phone_number.Int64
      : "";

    trackAmplitudeEvent("click_edit_my_phone_number");
    push("SetMyPhoneInfoPage", {
      mode: "edit",
      myNumber: myPhoneNumber,
    });
  };

  const handleQuitService = () => {
    trackAmplitudeEvent("click_quit_service");
    const result = confirm(
      "서비스를 탈퇴하시겠습니까?\n탈퇴 시 짝사랑 매칭 정보와 나를 좋아하는 사람들이 보낸 힌트를 모두 확인할 수 없게돼요."
    );
    if (!result) return;

    trackAmplitudeEvent("click_confirm_quit_service");
    deleteUser();
  };

  return (
    <AppScreen
      appBar={{
        iconColor: "#ffffff",
        title: (
          <div
            style={{
              color: "#ffffff",
            }}
          >
            설정
          </div>
        ),
      }}
    >
      <Container>
        <List>
          <BoxButton
            contents="내 번호 수정"
            subContents={
              meData?.user.my_phone_number.Valid
                ? "0" + meData?.user.my_phone_number.Int64
                : "등록"
            }
            endContents={
              <img src={ChevronRightIcon} alt="right" width={16} height={16} />
            }
            onClick={handleEditMyPhoneNumber}
          />
          <BoxButton
            contents="서비스 탈퇴하기"
            onClick={handleQuitService}
            endContents={
              <img src={ChevronRightIcon} alt="right" width={16} height={16} />
            }
          />
        </List>
      </Container>
    </AppScreen>
  );
};
