import { Message } from "@components/CheckSomePage/components/ChatBubbleItem";

export function parseMessages(text: string): Message[] {
  const lines = text
    .split("\n")
    .map((line) => line.trim())
    .filter((line) => line !== "");
  const dateRegex =
    /^[0-9]{4}년 [0-9]{1,2}월 [0-9]{1,2}일 [월화수목금토일]요일$/;

  const messages: Message[] = [];

  for (const line of lines) {
    if (dateRegex.test(line)) {
      continue;
    } else {
      const [sender, ...textParts] = line.split(" : ");
      if (sender && textParts.length > 0) {
        const text = textParts.join(" : ");
        messages.push({ sender, text });
      }
    }
  }

  return messages;
}

// 카카오 대화 내용에서 날짜와 시간을 제거하는 함수
export const removeDateTime = (text: string): string => {
  // 날짜 및 시간 패턴을 찾는 정규식
  const dateTimePattern =
    /\d{4}\. \d{1,2}\. \d{1,2}\. (오전|오후) \d{1,2}:\d{2},/gm;
  // 정규식을 사용하여 날짜와 시간을 제거
  return text.replaceAll(dateTimePattern, "").trim();
};

// textCount 와 가까운 곳에 위치한 날짜 분기 텍스트 부터 반환하는 함수
// chatGPT max token 때문에 날짜 기준으로 잘라야함
export function extractTextFromDate(text: string, textCount: number): string {
  // 텍스트를 뒤집어서 textCount번째 문자의 위치를 찾기

  // 텍스트의 길이에서 370을 뺀 인덱스를 찾기
  const targetIndex = text.length - textCount;

  // 날짜 분기 텍스트를 찾기 위한 정규식
  const dateRegex =
    /[0-9]{4}년 [0-9]{1,2}월 [0-9]{1,2}일 [월화수목금토일]요일/g;
  const matches = Array.from(text.matchAll(dateRegex));

  // 매치가 없다면 반환할 텍스트가 없음을 반환
  if (matches.length === 0) {
    return "날짜 분기 텍스트를 찾을 수 없습니다.";
  }

  // 뒤에서부터 370번째 문자에 가장 가까운 날짜 분기 텍스트의 위치를 찾기
  let closestMatch = matches[0];
  for (const match of matches) {
    if (match.index !== undefined && match.index <= targetIndex) {
      closestMatch = match;
    } else {
      break;
    }
  }

  // 가장 가까운 날짜 분기 텍스트 위치에서부터 끝까지 반환
  const startIndex = closestMatch.index ?? 0;
  return text.slice(startIndex);
}
