import styled from "@emotion/styled";

export const Button = styled.button`
  width: 100%;
  flex: 1;
  padding: 1rem 0;
  background-color: #0e0e11;
  color: #fff;
  border: none;
  border-radius: 3.5rem;
  font-family: "SUIT-Regular";
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  cursor: pointer;
`;

export const LoginButton = styled.button`
  width: 100%;
  flex: 1;
  margin-top: 0.2rem;
  padding: 0.5rem 0;
  /* background-color: #0e0e11; */
  color: #fff;
  text-decoration: underline;
  border: none;
  /* border-bottom: 1px solid #fff; */
  /* border-radius: 3.5rem; */
  font-family: "SUIT-Regular";
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  cursor: pointer;
`;
