import { AppScreen } from "@/stackflow/components";
import React, { ChangeEvent, useState } from "react";
import JSZip from "jszip";
import usePostCheckSome from "@/apis/hooks/usePostCheckSome";
import ChatBubbleList from "./components/ChatBubbleList";
import {
  extractTextFromDate,
  parseMessages,
  removeDateTime,
} from "@utils/kakaoTalkMessage";
import { Message } from "./components/ChatBubbleItem";
import { UploadIcon } from "@assets/uploadIcon";
import BoxButton from "@components/common/BoxButton";
import {
  Button,
  ButtonWrapper,
  ChevronRightIconImg,
  Container,
  DontKnowPhoneNumberText,
  ResultText,
  ResultTitle,
  ResultWrapper,
  Title,
} from "./index.css";
import { Spacing } from "@components/common/Spacing";
import useActiveActivities from "@/stackflow/hooks/useActiveActivities";
import { useFlow } from "@/stackflow";
import ChevronLeft from "@assets/ChevronLeft";
import { useActivity } from "@stackflow/react";

import ChevronRightIcon from "@/assets/shape_chevron_right.svg";

type ParsedResponseType = {
  percentage: string;
  is_sum: boolean;
  message: string;
};

const CheckSomePage = () => {
  const { pop, push } = useFlow();

  const inputRef = React.useRef<HTMLInputElement>(null);
  const [result, setResult] = useState<ParsedResponseType | undefined>(
    undefined
  );
  const [messages, setMessages] = useState<Message[]>([]);

  const { isRoot } = useActivity();

  const { isExistSpecificActivities, diffActivitiesFromCurrent } =
    useActiveActivities();

  const { mutate, isSuccess, isPending } = usePostCheckSome({
    onError: (error) => {
      console.log(error);
    },
    onSuccess: (data) => {
      if (!data.success) {
        alert("썸 판별에 실패했습니다. 다시 시도해주세요.");
        return;
      }
      try {
        const dataResult = data.result
          .replaceAll('"', `\\"`)
          .replaceAll("'", '"')
          .replaceAll("```json", "")
          .replaceAll("```", "")
          .replaceAll("\n", "");

        console.log("dataResult-----parsed result", dataResult);
        const parsedResult = JSON.parse(dataResult);

        if (typeof parsedResult === "string") {
          const reParse = JSON.parse(parsedResult) as ParsedResponseType;
          setResult(reParse);
          return;
        }

        setResult(parsedResult);
      } catch (e) {
        console.log("error", e);

        const dataResult = data.result
          .replaceAll('"', `\\"`)
          .replaceAll("'", '"')
          .replaceAll("```json", "")
          .replaceAll("```", "")
          .replaceAll("\n", "");
        setResult({
          percentage: "0%",
          is_sum: false,
          message: dataResult,
        });
      }
      // pop(100);
      // replace("HomePage", {});
    },
  });

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    // 파일이 없거나, 파일이 zip 형식이 아닐 경우
    if (!file || file.type !== "application/zip") {
      alert("zip 파일을 업로드해주세요.");
      return;
    }

    try {
      const zip = new JSZip();
      const zipContent = await zip.loadAsync(file);

      // txt 파일 찾기 (여러 파일 중 첫 번째 txt 파일을 예로 사용)
      const txtFileName = Object.keys(zipContent.files).find((fileName) =>
        fileName.endsWith(".txt")
      );

      if (txtFileName) {
        const textData = await zipContent.files[txtFileName].async("string");
        const removedDateTime = removeDateTime(textData);
        //끝에서부터 50000 글자만 가져오기
        const sliceText = extractTextFromDate(removedDateTime, 50000);

        // 로딩 보여주는 용으로 100개 메세지만 보여주기
        const slice100Messages = sliceText.split("\n").slice(0, 50).join("\n");
        setMessages(parseMessages(slice100Messages));

        mutate({ text: sliceText });
        // sendToServer({ content: removedDateTime }); // 서버로 데이터 전송 함수 호출
      } else {
        alert("zip 파일 안에 txt 파일이 없습니다.");
      }
    } catch (error) {
      console.error("Error while processing zip file", error);
    }
  };

  const handleBackToService = () => {
    const jwt = localStorage.getItem("jwt");
    if (jwt) {
      if (isExistSpecificActivities("MyPage")) {
        const popCount = diffActivitiesFromCurrent("MyPage");
        pop(popCount);
        return;
      }
      push("MyPage", {});
    }

    if (isExistSpecificActivities("HomePage")) {
      const popCount = diffActivitiesFromCurrent("HomePage");
      pop(popCount);
      return;
    }
    push("HomePage", {});
  };

  const loaderText = isPending
    ? "썸을 분석 중이에요..."
    : "썸 분석이 완료되었어요";

  const handleOpenUploadTalkGuide = () => {
    push("UploadTalkGuidePage", {});
  };

  return (
    <AppScreen
      appBar={{
        renderLeft: () =>
          isRoot ? (
            <ChevronLeft
              color={"white"}
              onClick={handleBackToService}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <></>
          ),
        iconColor: "#ffffff",
        title: (
          <div
            style={{
              color: "#ffffff",
            }}
          >
            썸 판별기
          </div>
        ),
      }}
      accessoryBar={
        isRoot ? (
          <ButtonWrapper>
            <Button onClick={handleBackToService}>
              짝남/짝녀 진짜 마음 확인하기
            </Button>
          </ButtonWrapper>
        ) : (
          <></>
        )
      }
    >
      <Container>
        <BoxButton
          contents={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: "100%",
                wordBreak: "keep-all",
              }}
            >
              <span
                style={{ fontSize: "18px", fontWeight: 900, color: "white" }}
              >
                대화 내용 업로드하기
              </span>
              <span
                style={{
                  whiteSpace: "wrap",
                }}
              >
                {`카카오톡 대화 내용을 바탕으로 "썸"인지 아닌지 판별해드려요.`}
              </span>
            </div>
          }
          onClick={() => {
            inputRef.current?.click();
          }}
          endContents={<UploadIcon style={{ color: "white" }} />}
        />
        <input
          type="file"
          ref={inputRef}
          onChange={handleFileChange}
          accept=".zip"
          hidden
        />
        <Spacing height={20} />
        <DontKnowPhoneNumberText onClick={handleOpenUploadTalkGuide}>
          <span>카카오톡 대화 업로드 방법 확인하기</span>
          <ChevronRightIconImg src={ChevronRightIcon} />
        </DontKnowPhoneNumberText>

        {messages && messages.length !== 0 && (
          <>
            <Spacing height={20} />
            <Title>{loaderText}</Title>
            <ChatBubbleList messages={messages} />
          </>
        )}
        {isSuccess && result && (
          <ResultWrapper>
            <ResultTitle>
              썸이 {result.is_sum ? "맞습니다." : "아닙니다."}
            </ResultTitle>
            <ResultText>{result.message}</ResultText>
          </ResultWrapper>
        )}
      </Container>
    </AppScreen>
  );
};

export default CheckSomePage;
