import React from "react";

import * as amplitude from "@amplitude/analytics-browser";
import { SpecificEventType } from "./event";

function useInitAmplitude() {
  amplitude.init("a3824c935105ac4ea3a43dd44324c08c");
}

export default useInitAmplitude;

export function trackAmplitudeEvent(event: SpecificEventType, params?: object) {
  const path = window.location.pathname;

  // Amplitude
  amplitude.track(event, {
    path: path,
    ...params,
  });
}
