import React from "react";
import {
  ContentsWrapper,
  LeftBox,
  PrefixIconWrapper,
  RightBox,
  SubContentsWrapper,
  Wrapper,
} from "./index.css";

type Props = {
  prefixIcon?: React.ReactNode | string;
  contents: React.ReactNode | string;
  subContents?: React.ReactNode | string;
  endContents?: React.ReactNode | string;
  direction?: "row" | "column";
  gap?: number;
  onClick?: () => void;
};

function BoxButton({
  onClick,
  contents,
  subContents,
  prefixIcon,
  endContents,
  direction = "row",
  gap = 8,
}: Props) {
  return (
    <Wrapper onClick={onClick}>
      <LeftBox>
        {prefixIcon && <PrefixIconWrapper>{prefixIcon}</PrefixIconWrapper>}
        <ContentsWrapper>
          <div
            style={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              float: "left",
              display: "flex",
              flexDirection: direction,
              alignItems: direction === "row" ? "center" : "flex-start",
              gap: gap,
            }}
          >
            {contents}
            {subContents && (
              <SubContentsWrapper>{subContents}</SubContentsWrapper>
            )}
          </div>
        </ContentsWrapper>
      </LeftBox>
      {endContents && <RightBox>{endContents}</RightBox>}
    </Wrapper>
  );
}

export default BoxButton;
